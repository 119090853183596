import axios, { AxiosResponse } from 'axios';

type RemoteComponentConfigurationPayload = {
    [component: string]: {
        enabled?: boolean;
    };
};

export type RemoteConfigurationPayload = {
    components?: RemoteComponentConfigurationPayload;
    enabled: boolean;
    url: string;
};

export type RemotesConfigurationPayload = {
    [remote: string]: RemoteConfigurationPayload;
};

type MicroFrontendConfigurationPayload = {
    remotes?: RemotesConfigurationPayload;
};

export const getMicroFrontendConfiguration = (): Promise<
    AxiosResponse<MicroFrontendConfigurationPayload>
> => axios.get('/micro-frontend/configuration');
