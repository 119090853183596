import _omitBy from 'lodash/omitBy';

import {
    RemoteConfigurationPayload,
    getMicroFrontendConfiguration,
} from './remotes/microFrontendClientApi';
import { RemotesConfiguration } from './remotes/types';

getMicroFrontendConfiguration()
    .then(({ data }) => {
        /* Omitting disabled remotes will trigger an error during module federation plugin
         * configuration interpolation, thus avoiding downloading the remoteEntry.js */
        const enabledRemotes = _omitBy(
            data.remotes,
            (remote: RemoteConfigurationPayload) => !remote.enabled
        ) as RemotesConfiguration;

        window.__federation_configuration__ = {
            ...window.__federation_configuration__,
            account_web_front: {
                ...data,
                remotes: enabledRemotes,
            },
        };
    })
    .catch((error) => {
        console.error(
            'Failed to load module federation configuration. Proceeding without module federation.',
            error
        );
        window.__federation_configuration__ = {
            ...window.__federation_configuration__,
            account_web_front: {
                remotes: undefined,
            },
        };
    })
    .finally(() => import('./bootstrap'));
